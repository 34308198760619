<template>
  <div class="art-sheet clearfix">
    <div v-if="response" class="art-layout-wrapper custom-wrapper-class">
      <div class="art-content-layout">
        <div class="art-content-layout-row">
          <div class="art-layout-cell art-content">
            <article class="art-post art-article">
              <div class="art-postcontent art-postcontent-0 clearfix">
                <div class="art-content-layout layout-item-0">
                  <div class="art-content-layout-row">
                    <div class="art-layout-cell layout-item-1" style="width: 100%">
                      <div class="art-content-layout-wrapper layout-item-old-0">
                        <div class="art-content-layout layout-item-old-1">
                          <div class="art-content-layout-row">
                            <div
                              :class="'art-layout-cell layout-item-old-2 ' + (response.status ? '' : 'blocked-block-back')"
                              style="width: 100%"
                            >
                              <img
                                v-if="!response.status"
                                src="images/Blocked-01.svg"
                                class="blocked-card"
                              />
                              <p style="text-align: center; margin: 0px !important;">
                                <img
                                  width="128"
                                  height="128"
                                  alt
                                  src="images/logo-01-3.png"
                                  class="image-logo-01"
                                />
                                <span class="image-logo-span">
                                  <br />
                                </span>
                              </p>
                              <div style="text-align: center; margin-bottom: 4px;">
                                <div>Karte Nr.</div>
                                <div class="image-logo-span">{{ response.card }}</div>
                              </div>
                              <div style="text-align: center">
                                <div style="text-align: center">Atlikums:</div>
                                <div
                                  :class="'response-bilance ' +(response.status? '' : 'blocked-block-back')                                    "
                                >{{ response.debit | currency }}</div>
                                <div style="display: block; font-size: small;">
                                  <span style="margin-right: 6px;">Mēneša beigās dzēšamie atlikumi:</span>
                                  <span
                                    class="burning-amount"
                                    style="color: red; font-size: large;"
                                  >{{ response.burn | currency}}</span>
                                </div>
                              </div>
                              <h4 style="text-align: justify; display: none">
                                <span class="span-class">
                                  <br />
                                </span>
                              </h4>
                              <h4 style="text-align: center; display: grid">
                                <span>Derīga līdz:</span>
                                <span
                                  :class="'response-valid ' +  (response.status? '' : 'blocked-block-back')"
                                >{{ response.valid | dt_only }}</span>
                              </h4>
                              <table style="width:100%;">
                                <tr class="response-date">
                                  <td style="border: none;"></td>
                                  <td
                                    style="border: none;text-align: right;"
                                  >{{ response.dt | dt_time }}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <footer class="art-footer">
      <p>
        <span style="color: #006bb8">Copyright © 2020. E-KARTE SIA</span>
        <br />
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "BurningCard",
  props: {
    response: {
      type: Object,
    },
  },
};
</script>

<style>
</style>