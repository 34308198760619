<template>
  <div class="home pa-0">
    <header class="art-header">
      <div class="art-shapes"></div>
    </header>
    <div v-if="error_message" class="error-div">{{ error_message }}</div>
    <v-sheet v-if="!response && !error_message" class="progress-sheet">
      <v-progress-circular indeterminate size="128" style="color: rgb(0, 107, 184);" width="10"></v-progress-circular>
    </v-sheet>
    <component :is="cardtype" :response.sync="response"></component>
  </div>
</template>
<script>
import * as cardTypes from "@/components/types";

export default {
  components: { ...cardTypes },
  name: "Home",
  data() {
    return {
      cardtype: "DebetCard",
      response: null,
      error_message: null,
    };
  },
  mounted() {
    try {
      this.error_message = null;
      let t = this.$api.getQueryVariable("c");
      if (!t) t = this.$api.getQueryVariable("t");
      if (!t) t = this.$api.getQueryVariable("f");
      if (t) {
        this.cardtype = "DebetCard";
        this.$api
          .getTokenInfo(t)
          .then((r) => {
            switch (r.ft) {
              case 2:
                this.cardtype = "BurningCard";
                break;
              case 0:
                this.cardtype = "FrameDebetCard";
                break;
              default:
                this.cardtype = "DebetCard";
            }
            this.response = r;
          })
          .catch((e) => {
            if (e.message.indexOf("status code 400")!=-1)
              this.error_message = "Kartes pārbaudes kļūda";
            else if (e.message.indexOf("status code 404")!=-1)
              this.error_message = "Karte nav atrasta vai nav aktivizēta";
            else this.error_message = e.message;
          });
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
.progress-sheet {
  text-align: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.response-bilance {
  font-weight: bold;
  /*line-height: 24px;*/
  line-height: 90% !important;
  font-family: "Times New Roman";
  font-size: 80px;
  color: green;
}
.response-valid {
  margin-top: 6px;
  font-size: 30px;
  line-height: 24px;
  color: green;
}
.response-date {
  color: rgb(29, 38, 48);
  font-size: x-small;
}
.blocked-card {
  position: absolute;
  height: 100% !important;
  width: 90%;
}
.blocked-block-back {
  background: lightgray !important;
  color: gray !important;
}
.error-div {
  text-align: center;
  margin: 20px;
  font-size: 25px;
  color: red;
}
.custom-wrapper-class {
  margin: 7px auto 0 auto !important;
}
.main-container {
  height: 100%;
  width: 100%;
  padding: 0px !important;
}

.art-content .art-postcontent-0 .layout-item-old-0 {
  margin-left: 0px;
}

.art-content .art-postcontent-0 .layout-item-old-1 {
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-width: 0px;
  border-top-color: #cfd8e2;
  border-right-color: #cfd8e2;
  border-bottom-color: #cfd8e2;
  border-left-color: #cfd8e2;
  border-collapse: separate;
  border-radius: 0px;
}

.art-content .art-postcontent-0 .layout-item-old-2 {
  color: #1d2630;
  background: #dbf5ff url("../public/images/9cb3e.png") scroll;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 0px;
}

.art-content .art-postcontent-0 .layout-item-0 {
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-bottom-color: #cfd8e2;
  border-collapse: separate;
}

.art-content .art-postcontent-0 .layout-item-1 {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #cfd8e2;
}

.ie7 .art-post .art-layout-cell {
  border: none !important;
  padding: 0 !important;
}

.ie6 .art-post .art-layout-cell {
  border: none !important;
  padding: 0 !important;
}
.image-logo-span {
  color: rgb(78, 104, 131);
  font-size: 24px;
  line-height: 28px;
}
.span-class {
  color: rgb(48, 63, 80);
  font-size: 13px;
  line-height: 22px;
}
.image-logo-01 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(156, 175, 196);
  border-right-color: rgb(156, 175, 196);
  border-bottom-color: rgb(156, 175, 196);
  border-left-color: rgb(156, 175, 196);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
}
</style>
