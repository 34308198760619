import axios from "axios";
const API = process.env.NODE_ENV == "development" ? "https://192.168.112.114/posapi" : "https://service.elektroniskaskartes.lv/posapi";
//axios.defaults.withCredentials = true;

const posapi = axios.create({
    withCredentials: true,
    baseURL: API,
});
const api = {
    api: API,
    getTokenInfo(token) {
        return this.apiPostRequest("cardinfo", { token: token });
    },
    // getFrameInfo(token) {
    //     return this.apiPostRequest("cardinfo", { frame: token });
    // },

    apiPostRequest(uri, data, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            posapi.post(uri, data, {
                params: pars, withCredentials: true,
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    apiGetRequest(uri, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            posapi.get(uri, { params: pars }).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        })
    },
    setVm(vm) {
        this.$vm = vm;
    },
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        //console.log('Query variable %s not found', variable);
    }
}
export default api;
