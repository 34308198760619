import moment from "moment";


const filters = [
    {
        name: "percent",
        func: (val) => {
            return val + "%";
        }
    },
    {
        name: "phone",
        func: (phone) => {
            return phone?(""+phone).replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'):"";
        }
    },
    {
        name: "currency",
        func: (val) => {
            let v = parseInt(val);
            if (isNaN(v)) return "";
            let nStr = (v / 100).toString();
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? ('.' + x[1] + (x[1].length == 1 ? '0' : '')) : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2 + " €";
        }
    },
    {
        name: "currency4",
        func: (val) => {
            let v = parseInt(val);
            if (isNaN(v)) return "";
            let nStr = (v / 10000).toString();
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? ('.' + x[1] + (x[1].length == 1 ? '0' : '')) : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2 + " €";
        }
    },
    {
        name: "cent2",
        func: (val) => {
            let v = parseInt(val);
            if (isNaN(v)) return "";
            let nStr = (v / 100).toString();
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? ('.' + x[1] + (x[1].length == 1 ? '0' : '')) : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2 + " ¢";
        }
    },
    {
        name: "dt_time",
        func: (val) => {
            if (!val) return "";
            //moment.locale(api.getSavedLocaleAsStr());
            if (val && val.constructor.name === 'Moment') return moment(val.toDate()).format("DD MMM YYYY");
            return moment(val).format("YYYY-MM-DD HH:mm");
        }
    },
    {
        name: "dt_only",
        func: (val) => {
            if (!val) return "";
            //moment.locale(api.getSavedLocaleAsStr());
            if (val && val.constructor.name === 'Moment') return moment(val.toDate()).format("DD MMM YYYY");
            return moment(val).format("DD MMM YYYY");
        }
    },
    {
        name: "realdate",
        func: (val) => {
            try {
                let ret = /<rdt>(.+)<\/rdt>/g.exec(val)
                return ret != null ? ret[1] : null;
            } catch (error) {
                return val;
            }
        }
    }
]

export default filters;