import Vue from 'vue'
import App from './App.vue'
import api from "@/api.js";
import filters from './filters.js';

Vue.config.productionTip = false
Vue.prototype.$api = api;

filters.forEach(f => {
  Vue.filter(f.name, f.func)
})

new Vue({
  render: h => h(App)
}).$mount('#art-main')
